import { IColorTheme, IScreenTheme, ITheme } from '../interfaces/Theme';

import defaultStyled, {
  createGlobalStyle,
  ThemedStyledInterface,
  ThemeProvider as SThemeProvider,
} from 'styled-components';

const themeColors: IColorTheme = {
  red100: 'red',
};

const themeScreens: IScreenTheme = {
  xSmall: 414,
  small: 576,
  medium: 768,
  large: 1024,
  xLarge: 1366,
  xxLarge: 1920,
  xxxLarge: 2560,
  xSmallMedia: '@media (min-width: 414px)',
  smallMedia: '@media (min-width: 576px)',
  mediumMedia: '@media (min-width: 768px)',
  defaultLandscapeMedia: '@media (max-width: 812px) and (max-height: 376px)',
  xSmallLandscapeMedia: '@media (max-width: 896px) and (max-height: 415px)',
  largeMedia: '@media (min-width: 1024px)',
  xLargeMedia: '@media (min-width: 1366px)',
  xxLargeMedia: '@media (min-width: 1920px)',
  xxxLargeMedia: '@media (min-width: 2560px)',
};

export const colors = themeColors;
export const screens = themeScreens;

export const theme = (): ITheme => ({ colors: themeColors, screens: themeScreens });
export const ThemeProvider = SThemeProvider;
export const styled: ThemedStyledInterface<ITheme> = defaultStyled;
export const GlobalStyle = createGlobalStyle`
  html, body {
    background: #FFF;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: grid;
    height: 100%;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
`;
