import { styled } from 'shared/theme';

export const LayoutWrapper = styled.div`
  height: 100%;
  width: 100%;

  .content-main {
    height: 100%;
    width: 100%;
  }
`;
