import AuthenticatedRoute from 'components/AuthenticatedRoute';
import HomePage from 'pages/Home';
import NotFoundPage from 'pages/NotFound';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { APP_ZONE } from 'shared/configs/App';
import { Zones } from 'shared/constants/Common';
import { ROUTES } from 'shared/constants/Routes';

const App: React.FC = () => {
  useEffect(() => {
    const scriptMarker = document.createElement('script');
    const script = document.createElement('script');

    if (APP_ZONE === Zones.Staging) {
      scriptMarker.text = `
        window.markerConfig = {
          destination: '627911a71ab423d5e2c27918', 
          source: 'snippet'
        };`;
      script.text = `!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);`;

      document.head.appendChild(scriptMarker);
      document.head.appendChild(script);
    }

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptMarker);
    };
  }, []);

  return (
    <Routes>
      <Route element={<AuthenticatedRoute />}>
        <Route path={ROUTES.HOME} element={<HomePage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
